import React from "react"
import classNames from "classnames"
import { StaticImage } from "gatsby-plugin-image"

import { pagesLinks } from "./links"
// import CoffeeIcon from "../../../components/_V2/BusinessTypes/assets/svg/CoffeeIcon"
// import RestaurantIcon from "../../../components/_V2/BusinessTypes/assets/svg/RestaurantIcon"
import componentStyles from "../../../components/_V2/BusinessTypes/styles.module.scss"
import {
	BUBBLES_TYPES,
	BUSINESS_TYPES,
	Data,
} from "../../../components/_V2/BusinessTypes/types"

import styles from "./styles.module.scss"

export const data: Data = {
	title: "",
	subTitle: "",
	readMore: "Смотреть интервью",
	theme: "grayLight",
	changeTitles: true, // Меняет тайтлы карточки, когда переключаешь слайды
	businessType: [
		{
			type: BUSINESS_TYPES.COFFEE_SHOP, // Нужно заменить данные
			tabName: pagesLinks.businessTypes.coffeehouse.text, // Нужно заменить данные
			// icon: CoffeeIcon, // Нужно заменить данные
			name: (
				<>
					Директор «Трип Кип»,
					<br />
					Эксклюзивный представитель Quick Resto в&nbsp;Беларуси
				</>
			),
			cardTitle: "Дмитрий Савченко", // Для changeTitles
			cardSubtitle: "отзывы наших партнеров", // Для changeTitles
			details: (
				<>
					Вместе с&nbsp;Quick Resto мы&nbsp;быстро закрыли проблемы малых
					и&nbsp;средних заведений общественного питания Беларуси
					в&nbsp;автоматизации. Модель сотрудничества, которую предлагает Quick
					Resto, максимально упрощает приобретение
				</>
			),
			detailsTablet: (
				<>
					Вместе с&nbsp;Quick Resto мы&nbsp;быстро закрыли проблемы малых
					и&nbsp;средних заведений общественного питания Беларуси
					в&nbsp;автоматизации. Модель сотрудничества, которую предлагает Quick
					Resto, максимально упрощает приобретение
				</>
			),
			detailsMobile: (
				<>
					Вместе с&nbsp;Quick Resto мы&nbsp;быстро закрыли проблемы малых
					и&nbsp;средних заведений общественного питания Беларуси
					в&nbsp;автоматизации. Модель сотрудничества, которую предлагает Quick
					Resto, максимально упрощает приобретение
				</>
			),
			// photoCaption: "«Здесь должна быть цитата»", // Нужно заменить данные
			// logo: (
			// 	<StaticImage
			// 		className={componentStyles.businessLogo}
			// 		src="../../../components/_V2/BusinessTypes/assets/images/logo/dobro-coffe.png"
			// 		alt="Логотип компании DO.BRO Coffee"
			// 		objectFit="contain"
			// 		placeholder="blurred"
			// 		quality={90}
			// 	/>
			// ),
			photo: (
				<StaticImage
					className={classNames(componentStyles.businessMainImageNew)}
					src="../../../components/_V2/BusinessTypes/assets/images/persons/dmitriy_savchenko.png"
					alt="Дмитрий Савченко"
					objectFit="cover"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			type: BUSINESS_TYPES.RESTAURANT, // Нужно заменить данные
			tabName: pagesLinks.businessTypes.restaurant.text, // Нужно заменить данные
			// icon: RestaurantIcon, // Нужно заменить данные
			bubbleType: BUBBLES_TYPES.SECONDARY,
			cardTitle: "Даниил Сонин", // Для changeTitles
			cardSubtitle: "отзывы наших партнеров", // Для changeTitles
			name: "Компания «Инструменты продаж», представитель Quick Resto",
			details: (
				<>
					В&nbsp;марте 2022 года наша компания столкнулась с&nbsp;проблемой
					срочного переноса более 1000 заведений на&nbsp;другую платформу.
					На&nbsp;сегодняшний день, Quick Resto наш единственный партнер
					в&nbsp;автоматизации общественного питания. Профессионализм,
					человеческие качества команды&nbsp;QR и&nbsp;удобство платформы
					позволяет на&nbsp;успешно развиваться нашей компании и&nbsp;ставить
					амбициозные цели на&nbsp;будущее!
				</>
			),
			detailsTablet: (
				<>
					В&nbsp;марте 2022 года наша компания столкнулась с&nbsp;проблемой
					срочного переноса более 1000 заведений на&nbsp;другую платформу.
					На&nbsp;сегодняшний день, Quick Resto наш единственный партнер
					в&nbsp;автоматизации общественного питания. Профессионализм,
					человеческие качества команды&nbsp;QR и&nbsp;удобство платформы
					позволяет на&nbsp;успешно развиваться нашей компании и&nbsp;ставить
					амбициозные цели на&nbsp;будущее!
				</>
			),
			detailsMobile: (
				<>
					В&nbsp;марте 2022 года наша компания столкнулась с&nbsp;проблемой
					срочного переноса более 1000 заведений на&nbsp;другую платформу.
					На&nbsp;сегодняшний день, Quick Resto наш единственный партнер
					в&nbsp;автоматизации общественного питания. Профессионализм,
					человеческие качества команды&nbsp;QR и&nbsp;удобство платформы
					позволяет на&nbsp;успешно развиваться нашей компании и&nbsp;ставить
					амбициозные цели на&nbsp;будущее!
				</>
			),
			// photoCaption: "«Здесь должна быть цитата»", // Нужно заменить данные
			// logo: (
			// 	<StaticImage
			// 		className={componentStyles.businessLogo}
			// 		src="../../../components/_V2/BusinessTypes/assets/images/logo/show-room.png"
			// 		alt="Логотип проекта «Шоу'рма»"
			// 		objectFit="contain"
			// 		placeholder="blurred"
			// 		quality={90}
			// 	/>
			// ),
			photo: (
				<StaticImage
					className={classNames(componentStyles.businessMainImageNew)}
					src="../../../components/_V2/BusinessTypes/assets/images/persons/daniil_sonin.png"
					alt="Даниил Сонин"
					objectFit="cover"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			type: BUSINESS_TYPES.RESTAURANT, // Нужно заменить данные
			tabName: pagesLinks.businessTypes.restaurant.text, // Нужно заменить данные
			// icon: RestaurantIcon, // Нужно заменить данные
			bubbleType: BUBBLES_TYPES.SECONDARY,
			cardTitle: "Денис Хабаров", // Для changeTitles
			cardSubtitle: "отзывы наших партнеров", // Для changeTitles
			name: "Директор Kafeline, Москва",
			details: (
				<>
					За&nbsp;10&nbsp;лет работы мы&nbsp;автоматизировали более 300 точек
					общепита по&nbsp;всей России. Система Quick Resto, с&nbsp;которой
					мы&nbsp;работаем уже три года, полностью закрывает потребности
					в&nbsp;автоматизации заведений в&nbsp;среднем бюджетном сегменте, при
					этом справляется с&nbsp;объектами со&nbsp;сложной внутренней
					структурой, например, франшизной сетью. Формат нашей работы
					с&nbsp;компанией нас устраивает на&nbsp;100%
				</>
			),
			detailsTablet: (
				<>
					За&nbsp;10&nbsp;лет работы мы&nbsp;автоматизировали более 300 точек
					общепита по&nbsp;всей России. Система Quick Resto, с&nbsp;которой
					мы&nbsp;работаем уже три года, полностью закрывает потребности
					в&nbsp;автоматизации заведений в&nbsp;среднем бюджетном сегменте, при
					этом справляется с&nbsp;объектами со&nbsp;сложной внутренней
					структурой, например, франшизной сетью. Формат нашей работы
					с&nbsp;компанией нас устраивает на&nbsp;100%
				</>
			),
			detailsMobile: (
				<>
					За&nbsp;10&nbsp;лет работы мы&nbsp;автоматизировали более 300 точек
					общепита по&nbsp;всей России. Система Quick Resto, с&nbsp;которой
					мы&nbsp;работаем уже три года, полностью закрывает потребности
					в&nbsp;автоматизации заведений в&nbsp;среднем бюджетном сегменте, при
					этом справляется с&nbsp;объектами со&nbsp;сложной внутренней
					структурой, например, франшизной сетью. Формат нашей работы
					с&nbsp;компанией нас устраивает на&nbsp;100%
				</>
			),
			// photoCaption: "«Здесь должна быть цитата»", // Нужно заменить данные
			// logo: (
			// 	<StaticImage
			// 		className={componentStyles.businessLogo}
			// 		src="../../../components/_V2/BusinessTypes/assets/images/logo/show-room.png"
			// 		alt="Логотип проекта «Шоу'рма»"
			// 		objectFit="contain"
			// 		placeholder="blurred"
			// 		quality={90}
			// 	/>
			// ),
			photo: (
				<StaticImage
					className={classNames(componentStyles.businessMainImageNew)}
					src="../../../components/_V2/BusinessTypes/assets/images/persons/denis_habarov.png"
					alt="Денис Хабаров"
					objectFit="cover"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			type: BUSINESS_TYPES.RESTAURANT, // Нужно заменить данные
			tabName: pagesLinks.businessTypes.restaurant.text, // Нужно заменить данные
			// icon: RestaurantIcon, // Нужно заменить данные
			bubbleType: BUBBLES_TYPES.SECONDARY,
			cardTitle: "Ахмед Раджабов", // Для changeTitles
			cardSubtitle: "отзывы наших партнеров", // Для changeTitles
			name: "представитель Quick Resto в Республике Дагестан",
			details: (
				<>
					С&nbsp;компанией Quick Resto мы&nbsp;успешно работаем уже 5&nbsp;лет.
					Это стало возможным благодаря внимательному отношению сотрудников
					компании к&nbsp;своим партнёрам. Отметим профессионализм,
					компетентность и&nbsp;оперативность специалистов технической
					поддержки. Отметим профессионализм, компетентность
					и&nbsp;оперативность специалистов технической поддержки.
				</>
			),
			detailsTablet: (
				<>
					С&nbsp;компанией Quick Resto мы&nbsp;успешно работаем уже 5&nbsp;лет.
					Это стало возможным благодаря внимательному отношению сотрудников
					компании к&nbsp;своим партнёрам. Отметим профессионализм,
					компетентность и&nbsp;оперативность специалистов технической
					поддержки. Отметим профессионализм, компетентность
					и&nbsp;оперативность специалистов технической поддержки.
				</>
			),
			detailsMobile: (
				<>
					С&nbsp;компанией Quick Resto мы&nbsp;успешно работаем уже 5&nbsp;лет.
					Это стало возможным благодаря внимательному отношению сотрудников
					компании к&nbsp;своим партнёрам. Отметим профессионализм,
					компетентность и&nbsp;оперативность специалистов технической
					поддержки. Отметим профессионализм, компетентность
					и&nbsp;оперативность специалистов технической поддержки.
				</>
			),
			// photoCaption: "«Здесь должна быть цитата»", // Нужно заменить данные
			// logo: (
			// 	<StaticImage
			// 		className={componentStyles.businessLogo}
			// 		src="../../../components/_V2/BusinessTypes/assets/images/logo/show-room.png"
			// 		alt="Логотип проекта «Шоу'рма»"
			// 		objectFit="contain"
			// 		placeholder="blurred"
			// 		quality={90}
			// 	/>
			// ),
			photo: (
				<StaticImage
					className={classNames(componentStyles.businessMainImageNew)}
					src="../../../components/_V2/BusinessTypes/assets/images/persons/ahmed_radzhabov.png"
					alt="Ахмед Раджабов"
					objectFit="cover"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
	],
}

export default data
