import * as React from "react"
import { SVGProps } from "react"

const Three = (props: SVGProps<SVGSVGElement>) => (
	<svg
		{...props}
		width="33"
		height="32"
		viewBox="0 0 33 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M17.3368 23.5324V24C17.3368 26.2091 15.546 28 13.3368 28H9.34729L8.10972 21.0236L6.06641 20.2705L8.67432 15.0869L8.68233 14.0924C8.81064 8.49669 13.3869 4 19.0134 4C24.7207 4 29.3473 8.62663 29.3473 14.3339C29.3473 17.6544 27.7812 20.6091 25.3473 22.4997V28"
			stroke="#3021E5"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M24.0482 13.8705V10.0108L20.1884 10.0107M23.4555 10.6035L18.9599 15.0991L17.19 13.3293L13.0605 17.455"
			stroke="#3021E5"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default Three
