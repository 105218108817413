import * as React from "react"

const TakeMyMoney = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		{...props}
		width="178"
		height="140"
		viewBox="0 0 178 140"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clip-path="url(#clip0_18022_4412)">
			<path
				d="M33.4169 29.3545C19.4169 36.6345 8.8329 49.4165 4.36689 64.6485C2.42089 71.2145 1.62289 78.2565 2.18289 85.4805C2.98089 96.2745 6.70489 106.186 12.4869 114.418C23.8549 130.812 43.3989 140.864 64.7629 139.268C67.9269 139.03 71.0349 138.554 74.0309 137.812C81.3669 136.062 88.1429 132.926 94.0649 128.67C96.1929 127.158 98.1809 125.506 100.071 123.756H100.099C101.849 122.104 103.501 120.354 105.041 118.52"
				stroke="#2A2D33"
				strokeWidth="2.1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M89.8379 31.7627C94.8359 34.7167 99.3719 38.4267 103.306 42.7667C110.082 50.2007 115.024 59.5667 117.208 70.1647C117.53 71.7607 117.796 73.3567 117.978 74.9527"
				stroke="#2A2D33"
				strokeWidth="2.1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M73.5108 73.2529C80.4088 70.84 82.9513 63.0065 83.551 56.29"
				stroke="#2A2D33"
				strokeWidth="2.1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M51.4634 58.7968C49.0134 57.9148 46.9974 55.7728 46.5494 53.2108C46.1014 50.6348 47.4034 47.7648 49.7694 46.6728C52.1354 45.5528 55.3554 46.5608 56.4614 48.8988C59.1494 44.5168 58.6454 38.4548 55.3694 34.4648C56.3494 35.2628 58.0854 35.0808 59.0094 34.0868C60.0174 32.9388 60.0734 31.1888 59.5414 29.7608C59.0094 28.3328 57.9734 27.1288 56.9934 26.0088C58.6734 27.9548 64.6234 28.7248 67.0874 29.1728C70.6714 29.8588 74.3394 30.6568 78.0354 30.6008C82.3194 30.5168 85.0214 29.0328 85.0214 29.0328C87.5134 32.0568 87.0094 37.7968 86.6174 40.4008C86.1414 43.5368 85.2314 46.5608 84.5174 49.6268"
				stroke="#2A2D33"
				strokeWidth="2.1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M78.3994 56.0106C79.8554 57.1446 81.9834 57.2006 83.5234 56.2906C83.7754 56.1506 83.9854 55.9546 84.2094 55.7726C85.7494 54.3166 86.0014 51.6986 84.7834 49.9906C84.6994 49.8786 84.6154 49.7666 84.5034 49.6546C83.3694 48.2826 81.4934 47.6386 79.7294 47.1206"
				stroke="#2A2D33"
				strokeWidth="2.1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M85.0642 29.1447C87.1362 28.2627 88.4802 26.1347 88.9282 23.9367C89.3622 21.7247 88.9982 19.4427 88.4242 17.2727C86.7442 10.9867 83.1882 5.20475 78.3302 0.864746C78.4842 3.79075 76.9302 6.74475 74.4242 8.27075C73.4442 4.89675 70.2102 2.32075 66.7102 2.11075C68.3062 4.04275 67.8722 6.96875 66.7942 9.22275C66.1362 10.6087 65.1982 11.9387 63.8402 12.6247C61.7822 13.6607 59.3182 13.0167 57.0082 13.0027C51.6882 12.9887 46.6062 16.9787 45.3742 22.1587C45.4022 21.1647 45.4162 20.1147 44.8842 19.2747C44.3522 18.4347 43.0642 18.0147 42.3502 18.7007C41.9862 19.0507 41.8602 19.5967 41.8182 20.1147C41.6782 21.5847 41.9722 23.0827 42.6442 24.3987C41.4682 22.8867 39.5642 21.3327 37.8282 22.1307C36.3162 22.8307 36.0362 25.0287 36.8482 26.4847C37.6602 27.9407 39.1722 28.8507 40.6142 29.6907C37.7022 37.0407 40.2362 46.1827 46.5082 50.9847"
				stroke="#2A2D33"
				strokeWidth="2.1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M51.4776 58.7969C51.4496 62.5769 50.7356 66.7489 49.3496 70.2629"
				stroke="#2A2D33"
				strokeWidth="2.1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M62.748 77.6397C65.632 82.9877 67.4241 88.9097 68.0121 94.9577C68.0401 94.8457 75.67 82.6237 78.918 76.3097C77.756 75.4137 76.594 74.5317 75.432 73.6357"
				stroke="#2A2D33"
				strokeWidth="2.1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M50.5539 65.2077C49.2519 65.1657 47.9359 65.2497 46.6479 65.4737C45.7519 65.6277 44.7019 65.9917 44.4919 66.8737C44.2679 67.7977 45.1079 68.5957 45.8639 69.1557C50.8899 72.8797 56.7419 75.6097 62.6779 77.6117"
				stroke="#2A2D33"
				strokeWidth="2.1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M73.7236 85.7326C75.6976 86.3346 77.5316 87.3985 79.0436 88.7985C79.4216 84.7245 79.1696 80.3845 78.9316 76.3105"
				stroke="#2A2D33"
				strokeWidth="2.1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M62.6639 78.2981C61.6559 82.4561 61.4039 86.7961 61.9079 91.0521C58.1279 89.8621 54.3899 88.4621 50.9319 86.5301C47.4739 84.5981 44.2679 82.1341 41.8039 79.0261C41.2719 78.3541 40.7679 77.6261 40.5579 76.7861C40.3759 76.0581 40.4179 75.2881 40.5299 74.5321C40.9779 71.3961 42.5739 68.8621 44.6599 66.4541"
				stroke="#2A2D33"
				strokeWidth="2.1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M2.78516 90.5196C11.7732 79.0116 26.2492 71.8436 40.8932 72.2076"
				stroke="#2A2D33"
				strokeWidth="2.1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M134.722 104.842C127.12 110.764 117.978 113.452 108.444 114.068C100.212 114.656 91.6436 113.69 83.4676 112.08C81.7736 119.598 80.0096 127.228 76.3976 134.032C75.7116 135.334 74.9416 136.58 74.0596 137.812"
				stroke="#2A2D33"
				strokeWidth="2.1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M127.386 78.4111L104.034 85.1311L79.5059 78.3271"
				stroke="#2A2D33"
				strokeWidth="2.1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14.4201 116.993C14.2381 110.595 15.1061 104.155 17.0241 98.0234"
				stroke="#2A2D33"
				strokeWidth="2.1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M73.511 73.2617C71.243 73.9477 68.961 73.9617 66.665 73.3877"
				stroke="#2A2D33"
				strokeWidth="2.1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M73.5854 73.4114L71.4619 82.799"
				stroke="#2A2D33"
				strokeWidth="2.1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M74.7174 39.2948C73.0654 38.1048 69.8594 37.9648 68.0674 38.5388"
				stroke="#2A2D33"
				strokeWidth="2.1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M86.5188 39.2525C86.1128 38.7345 83.4388 38.4685 81.5488 39.3925"
				stroke="#2A2D33"
				strokeWidth="2.1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M69.3633 59.8237C71.8997 63.5654 77.39 64.3442 82.2414 62.7686"
				stroke="#2A2D33"
				strokeWidth="2.1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M83.3808 44.3182C83.5036 43.2661 83.0553 42.3492 82.3795 42.2703C81.7037 42.1914 81.0562 42.9803 80.9334 44.0324C80.8105 45.0846 81.2588 46.0015 81.9346 46.0804C82.6104 46.1593 83.2579 45.3703 83.3808 44.3182Z"
				fill="#2A2D33"
			/>
			<path
				d="M73.0151 44.1821C73.1442 43.0762 72.6761 42.1128 71.9696 42.0303C71.263 41.9478 70.5856 42.7775 70.4565 43.8833C70.3273 44.9892 70.7954 45.9526 71.502 46.0351C72.2085 46.1176 72.886 45.288 73.0151 44.1821Z"
				fill="#2A2D33"
			/>
			<path
				d="M126.686 84.1926L131.306 82.4146L133.644 81.5046C134.554 81.0006 135.982 77.3046 140.952 74.4346C141.596 74.0566 142.142 73.5526 142.786 73.1606C145.278 71.6206 146.902 69.1426 148.176 66.1466C149.8 63.2206 153.09 64.7326 152.432 67.6026C151.69 70.7666 151.158 72.6286 149.534 75.3586C159.026 74.6166 166.726 75.7786 171.668 76.9546C176.078 78.2846 174.454 81.5186 172.62 81.6586C175.364 81.7706 176.148 85.9566 172.62 86.5726L166.698 87.4546C171.08 87.9586 170.45 91.6266 167.678 92.5366C153.538 97.5626 142.702 97.1846 138.124 94.6926L135.548 96.4426L130.816 99.6626"
				stroke="#2A2D33"
				strokeWidth="2.1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M166.725 87.4256C163.099 87.3836 159.529 88.0836 156.225 88.7556"
				stroke="#2A2D33"
				strokeWidth="2.1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M172.648 81.6734C169.022 81.6314 159.53 81.8274 156.226 82.4854"
				stroke="#2A2D33"
				strokeWidth="2.1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M149.533 75.5127C149.953 78.7467 149.589 82.0787 148.455 85.1307"
				stroke="#2A2D33"
				strokeWidth="2.1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M131.32 82.4141C129.99 79.6561 128.632 78.0041 127.708 78.2701C126.728 78.5361 126.406 80.8461 126.7 84.1921C126.91 86.5021 127.442 89.3161 128.268 92.2981C129.038 95.0841 129.92 97.6181 130.844 99.6621C132.328 102.98 133.84 105.01 134.89 104.716C136.08 104.394 136.304 101.02 135.576 96.4561"
				stroke="#2A2D33"
				strokeWidth="2.1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_18022_4412">
				<rect width="178" height="140" fill="white" />
			</clipPath>
		</defs>
	</svg>
)

export default TakeMyMoney
