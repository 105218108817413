import React from "react"

import styles from "./styles.module.scss"

export default {
	heading: "Знакомься — наша партнёрка",
	table: [
		{
			heading: "Преимущества",
			list: [
				"Дополнительный доход",
				"Первые 2 месяца повышенный процент выплаты",
				"Процент вознаграждения увеличивается в зависимости от количества подключенных пользователей",
			],
		},
		{
			heading: "Условия",
			list: [
				"Оставить заявку",
				"Зарегистрировать личный кабинет партнера",
				"Пройти сертификацию и подписать договор",
				"Для подтверждения статуса «партнер» необходимо подключить от 3-х пользователей в течение квартала",
			],
		},
		{
			heading: "Вознаграждение",
			list: [
				"Регулярные выплаты",
				"Платим за каждого приведенного клиента, пока он пользуется системой",
				"Чем больше клиентов - тем выше процент",
				"Предлагай дополнительные продукты и повышай вознаграждение (приложение для гостей, сайт, электронное меню, карты лояльности)",
			],
		},
	],
}
