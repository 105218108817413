import * as React from "react"
import { SVGProps } from "react"

const Thirteen = (props: SVGProps<SVGSVGElement>) => (
	<svg
		{...props}
		width="32"
		height="32"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M15.9985 2.66666L3.66016 8.03979L15.9985 13.7612"
			stroke="#1BC495"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M15.9976 2.66666L28.3359 8.03979L15.9976 13.7612"
			stroke="#1BC495"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M21.7559 5.17391L9.61466 10.8039V16.3842"
			stroke="#1BC495"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M3.66016 8.43781V23.6119L15.9985 29.3333"
			stroke="#1BC495"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M28.3359 8.28857L28.3359 23.6119L15.9975 29.3333"
			stroke="#1BC495"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M15.9883 29.0231V14.0215"
			stroke="#1BC495"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default Thirteen
