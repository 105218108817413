import * as React from "react"
import { SVGProps } from "react"

const Six = (props: SVGProps<SVGSVGElement>) => (
	<svg
		{...props}
		width="33"
		height="32"
		viewBox="0 0 33 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clip-path="url(#clip0_4525_89014)">
			<path
				d="M28.5449 17.5801V4.45094H15.4158L28.5449 17.5801Z"
				stroke="#3021E5"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<rect
				width="11.6703"
				height="16.0467"
				transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 26.1094 15.1415)"
				stroke="#3021E5"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M26.0491 15.2004L13.0325 11.7126M19.5849 21.6646L6.56829 18.1768"
				stroke="#3021E5"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10.6055 22.3916L5.44786 27.5492"
				stroke="#3021E5"
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_4525_89014">
				<rect
					width="32"
					height="32"
					fill="white"
					transform="translate(0.996094)"
				/>
			</clipPath>
		</defs>
	</svg>
)

export default Six
