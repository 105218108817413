import * as React from "react"
import { SVGProps } from "react"

const Four = (props: SVGProps<SVGSVGElement>) => (
	<svg
		{...props}
		width="33"
		height="32"
		viewBox="0 0 33 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M24.4959 16C24.4959 20.1421 21.1381 23.5 16.996 23.5C12.8539 23.5 9.49609 20.1421 9.49609 16C9.49609 11.8579 12.8539 8.5 16.996 8.5"
			stroke="#3021E5"
			strokeWidth="2"
			strokeMiterlimit="10"
			strokeLinecap="round"
		/>
		<path
			d="M28.8299 14C28.939 14.6504 28.9958 15.3186 28.9958 16C28.9958 22.6274 23.6233 28 16.996 28C10.3686 28 4.99609 22.6274 4.99609 16C4.99609 9.37258 10.3686 4 16.996 4C17.6773 4 18.3455 4.05679 18.9958 4.1659"
			stroke="#3021E5"
			strokeWidth="2"
			strokeMiterlimit="10"
			strokeLinecap="round"
		/>
		<path
			d="M16.9961 16L20.9961 12"
			stroke="#3021E5"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M28.9961 8L24.9961 12H20.9961V8L24.9961 4V8"
			stroke="#3021E5"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default Four
