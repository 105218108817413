import * as React from "react"
import { SVGProps } from "react"

const One = (props: SVGProps<SVGSVGElement>) => (
	<svg
		{...props}
		width="33"
		height="32"
		viewBox="0 0 33 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M10.6094 3H23.3931"
			stroke="#3021E5"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M19.3886 6.87915C19.3886 8.19995 18.3178 9.27067 16.997 9.27067C15.6856 9.27067 14.6208 8.21519 14.6056 6.90741C14.6055 6.89801 14.6055 6.88858 14.6055 6.87915"
			stroke="#3021E5"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M19.3887 6.87906C19.3887 6.88849 19.3887 6.89791 19.3888 6.90732M19.3888 6.90732C19.404 8.2151 20.4688 9.27057 21.7802 9.27057C23.101 9.27057 24.1718 8.19985 24.1718 6.87906L23.4245 3.04956M19.3888 6.90732V3.04956"
			stroke="#3021E5"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M14.6055 6.87906C14.6055 6.88849 14.6054 6.89791 14.6053 6.90732M14.6053 6.90732C14.5902 8.2151 13.5253 9.27057 12.2139 9.27057C10.8931 9.27057 9.82236 8.19985 9.82236 6.87906L10.5696 3.04956M14.6053 6.90732V3.04956"
			stroke="#3021E5"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M10.9961 9.50049V17.0142H22.9607V13.0285"
			stroke="#3021E5"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M18.2061 17.0141V13.0088H15.7227"
			stroke="#3021E5"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M13.4507 24.7658H17.7448C18.808 24.7658 19.6619 23.8889 19.6337 22.8262C19.6065 21.8025 18.7688 20.9868 17.7448 20.9868H9.70128C8.60741 20.9868 7.56126 21.4348 6.80639 22.2264L5.0957 24.0205"
			stroke="#3021E5"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M5.0957 28.9847H19.9584C21.0818 28.9847 22.1535 28.5123 22.9113 27.6829L28.3885 21.6885C29.1023 20.9073 29.0595 19.6985 28.2922 18.9697C27.5286 18.2443 26.326 18.2596 25.5811 19.0042L20.98 23.6029C20.2299 24.3526 19.2128 24.7738 18.1523 24.7738H12.9184"
			stroke="#3021E5"
			strokeWidth="2"
			strokeLinecap="round"
		/>
	</svg>
)

export default One
