import * as React from "react"
import { SVGProps } from "react"

const Ten = (props: SVGProps<SVGSVGElement>) => (
	<svg
		{...props}
		width="32"
		height="32"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M9.09866 13.0088H7.91406C6.80949 13.0088 5.91406 13.9042 5.91406 15.0088V25.9984C5.91406 27.1029 6.80949 27.9984 7.91406 27.9984H24.0876C25.1921 27.9984 26.0876 27.1029 26.0876 25.9984V15.0088C26.0876 13.9042 25.1921 13.0088 24.0876 13.0088H22.903"
			stroke="#1BC595"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M9.6582 4L11.7722 5.49302L13.8862 4L16.0002 5.49302L18.1141 4L20.2281 5.49302L22.3421 4V18.9896H9.6582V4Z"
			stroke="#1BC595"
			strokeWidth="2"
			strokeLinejoin="round"
		/>
		<path
			d="M7.04102 22.9756H22.3243"
			stroke="#1BC595"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M12.7676 14.9551H15.5001M19.2327 14.9551H18.3117"
			stroke="#1BC595"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M12.7676 11.0625H19.2327"
			stroke="#1BC595"
			strokeWidth="2"
			strokeLinecap="round"
		/>
	</svg>
)

export default Ten
