import * as React from "react"
import { SVGProps } from "react"

const Two = (props: SVGProps<SVGSVGElement>) => (
	<svg
		{...props}
		width="33"
		height="32"
		viewBox="0 0 33 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M10.0948 13.0088H8.91016C7.80559 13.0088 6.91016 13.9042 6.91016 15.0088V25.9984C6.91016 27.1029 7.80559 27.9984 8.91016 27.9984H25.0837C26.1882 27.9984 27.0837 27.1029 27.0837 25.9984V15.0088C27.0837 13.9042 26.1882 13.0088 25.0837 13.0088H23.8991"
			stroke="#3021E5"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M10.6543 4L12.7683 5.49302L14.8823 4L16.9963 5.49302L19.1102 4L21.2242 5.49302L23.3382 4V18.9896H10.6543V4Z"
			stroke="#3021E5"
			strokeWidth="2"
			strokeLinejoin="round"
		/>
		<path
			d="M8.03711 22.9756H23.3204"
			stroke="#3021E5"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M13.7637 14.9551H16.4962M20.2288 14.9551H19.3078"
			stroke="#3021E5"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M13.7637 11.0625H20.2288"
			stroke="#3021E5"
			strokeWidth="2"
			strokeLinecap="round"
		/>
	</svg>
)

export default Two
