import React from "react"
import data from "./data"
import ProductPresentationBase from "../../../components/_V2/ProductPresentationBase"
import styles from "./styles.module.scss"

interface Props {
	className?: string
}

export default function ProductPresentation(props: Props) {
	return (
		<ProductPresentationBase
			sectionClass={styles.section}
			containerClass={styles.content}
			data={data}
			className={props.className}
			backdropClass={styles.backdrop}
		/>
	)
}
