import * as React from "react"
import { SVGProps } from "react"

const Eight = (props: SVGProps<SVGSVGElement>) => (
	<svg
		{...props}
		width="33"
		height="32"
		viewBox="0 0 33 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect
			x="2.99609"
			y="3.23193"
			width="28"
			height="19.4796"
			rx="2"
			stroke="#3021E5"
			strokeWidth="2"
		/>
		<path
			d="M9.69141 28.7684H24.3001"
			stroke="#3021E5"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M14.5605 22.8843L14.5605 28.7684"
			stroke="#3021E5"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M19.4297 22.8843L19.4297 28.7684"
			stroke="#3021E5"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M12.6758 12.4078V16.4885C12.6758 17.5718 14.6012 18.4501 16.9762 18.4501C19.3513 18.4501 21.2767 17.5718 21.2767 16.4885L21.2766 16.478L21.2767 12.4078M9.67188 10.8977L16.9762 7.49353L24.2806 10.8977L16.9762 14.3018L9.67188 10.8977Z"
			stroke="#3021E5"
			strokeWidth="2"
			strokeLinejoin="round"
		/>
	</svg>
)

export default Eight
