import * as React from "react"
import { SVGProps } from "react"

const Nine = (props: SVGProps<SVGSVGElement>) => (
	<svg
		{...props}
		width="33"
		height="32"
		viewBox="0 0 33 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M5.66406 28.0001C5.66406 25.0546 8.05188 22.6667 10.9974 22.6667H22.9974C25.9429 22.6667 28.3307 25.0546 28.3307 28.0001V29.3334H5.66406V28.0001Z"
			stroke="#3021E5"
			strokeWidth="2"
			strokeLinejoin="round"
		/>
		<path
			d="M11.8379 23.3334L15.8379 28.6667"
			stroke="#3021E5"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M22.1523 23.3334L18.1523 28.6667"
			stroke="#3021E5"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M16.9961 18.6666C21.4144 18.6666 24.9961 15.0849 24.9961 10.6666C24.9961 6.24835 21.4144 2.66663 16.9961 2.66663C12.5778 2.66663 8.99609 6.24835 8.99609 10.6666C8.99609 15.0849 12.5778 18.6666 16.9961 18.6666Z"
			stroke="#3021E5"
			strokeWidth="2"
			stroke-miterlimit="10"
			strokeLinecap="round"
		/>
		<path
			d="M19.6634 10.6666C19.6634 12.1394 18.4695 13.3333 16.9967 13.3333C15.524 13.3333 14.3301 12.1394 14.3301 10.6666"
			stroke="#3021E5"
			strokeWidth="2"
			stroke-miterlimit="10"
			strokeLinecap="round"
		/>
	</svg>
)

export default Nine
