import React, { useState, useEffect, useRef } from "react"
import classNames from "classnames"

import { PhoneInput } from "../PhoneInput/PhoneInput"

import { requestForConsultation } from "../../../apiRequests/callToAction"
import { getPhoneValidState } from "../../../utils/validateFunctions"
import { consultFormSendEvent } from "../../../gtm-events"

import PhoneBackdropDesktop from "./assets/PhoneBackdropDesktop"
import PhoneBackdropTablet from "./assets/PhoneBackdropTablet"
import PhoneBackdropMobile from "./assets/PhoneBackdropMobile"

import styles from "./styles.module.scss"

export enum ACTION_STATUSES {
	SENDING = "SENDING",
	NOT_SEND = "NOT_SEND",
	SUCCESS = "SUCCESS",
	FAIL = "FAIL",
}

interface Props {
	className?: string

	data: {
		icon: JSX.Element
		heading: string | JSX.Element
		textarea?: string | JSX.Element
		btn: string
		inputTitle: string
		successTitle: string
		successDetails: string
		successAction: string
	}
	request?: Function
}

type validityState = {
	valid: boolean
	message: string
}

export default function RequestCall(props: Props) {
	const [actionStatus, setActionStatus] = useState<ACTION_STATUSES>(
		ACTION_STATUSES.NOT_SEND
	)
	const [isFormFilled, setIsFormFilled] = useState<boolean>(false)
	const [isSuccessView, setIsSuccessView] = useState<boolean>(false)

	const formDataInitial = {
		phone: {
			value: "",
			errorMessage: "",
			isFilled: false,
		},
	}

	const [formData, setFormData] = useState(formDataInitial)

	useEffect(() => {
		if (formData.phone.isFilled) {
			setIsFormFilled(true)
		} else {
			setIsFormFilled(false)
		}
	}, [formData])

	useEffect(() => {
		if (isSuccessView) {
			// @ts-ignore
			successFormBtn.current.focus()
		}
	}, [isSuccessView])

	const validateInput = (phone: string) => {
		return getPhoneValidState(phone)
	}

	const defaultErrors: { phone: string } = {
		phone: "Введите корректный номер телефона",
	}

	const handleInputChange = (
		value: string,
		isFilled: boolean,
		name: string,
		errorMessage: string
	) => {
		if (!errorMessage) {
			// @ts-ignore
			errorMessage = defaultErrors[name]
		}
		setFormData({
			...formData,
			[name]: {
				errorMessage: !isFilled ? errorMessage : "",
				value,
				isFilled,
			},
		})
	}

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		setActionStatus(ACTION_STATUSES.SENDING)
		try {
			const inputValidityState: validityState = await validateInput(
				formData.phone.value
			)
			if (inputValidityState.valid) {
				if (props.request) {
					await props.request(formData.phone.value)
				} else {
					await requestForConsultation(formData.phone.value)
				}
				setFormData({
					...formData,
					phone: {
						...formData.phone,
						errorMessage: "",
					},
				})
				setIsSuccessView(true)
				setActionStatus(ACTION_STATUSES.SUCCESS)

				consultFormSendEvent()
			} else {
				setFormData({
					...formData,
					phone: {
						...formData.phone,
						errorMessage: inputValidityState.message,
					},
				})
				setActionStatus(ACTION_STATUSES.NOT_SEND)
			}
		} catch (error) {
			// await errorAmoOffer()
			setFormData(formDataInitial)

			setActionStatus(ACTION_STATUSES.FAIL)
		}
	}

	const successFormBtn = useRef(null)

	return (
		<section className={classNames(styles.section, props.className)}>
			<div className={styles.content__row}>
				<div className={styles.content__column}>
					<div className={styles.block}>
						{props.data.icon}
						<div className={styles.block__content}>
							<h2 className={styles.block__heading}>{props.data.heading}</h2>
							<p className={styles.block__textarea}>{props.data.textarea}</p>
						</div>
					</div>
				</div>
				<div className={styles.content__column}>
					<div className={styles.form__container}>
						{!isSuccessView ? (
							<>
								<form className={styles.form} onSubmit={handleSubmit}>
									<div className={styles.form__phoneContainer}>
										<PhoneInput
											className={styles.form__phone}
											inputId={"requestCall"}
											inputTitle={props.data.inputTitle}
											value={formData.phone.value}
											onInputChange={(value, isInputFilled) =>
												handleInputChange(
													value,
													isInputFilled,
													"phone",
													defaultErrors.phone
												)
											}
											actionStatus={actionStatus}
											errorMessage={formData.phone.errorMessage}
											isBig={false}
											extraProps={{
												required: true,
												autoFocus: true,
											}}
										/>
										<PhoneBackdropTablet
											className={classNames(
												styles.backdrop,
												styles.backdrop__tablet
											)}
										/>
										<PhoneBackdropTablet
											className={classNames(
												styles.backdrop,
												styles.backdrop__mobile
											)}
										/>
									</div>

									<button
										className={styles.form__button}
										type="submit"
										disabled={
											!isFormFilled ||
											actionStatus === ACTION_STATUSES.SUCCESS ||
											actionStatus === ACTION_STATUSES.SENDING
										}
									>
										{props.data.btn}
									</button>
								</form>

								<PhoneBackdropDesktop
									className={classNames(
										styles.backdrop,
										styles.backdrop__desktop
									)}
								/>
							</>
						) : (
							<span className={styles.success}>
								{props.data.successDetails}
							</span>
						)}
					</div>
				</div>
			</div>
		</section>
	)
}
