import * as React from "react"
import { SVGProps } from "react"

const Sixteen = (props: SVGProps<SVGSVGElement>) => (
	<svg
		{...props}
		width="27"
		height="26"
		viewBox="0 0 27 26"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M6.48438 6H16.4844"
			stroke="#5F47BF"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M6.48438 10H12.4844"
			stroke="#5F47BF"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M5.98442 15H6.98442C6.98442 14.4477 6.53671 14 5.98442 14V15ZM5.98442 20H4.98442C4.98442 20.4045 5.22806 20.7691 5.60174 20.9239C5.97541 21.0787 6.40553 20.9931 6.69153 20.7071L5.98442 20ZM10.9844 15V14C10.7192 14 10.4649 14.1054 10.2773 14.2929L10.9844 15ZM17.9844 15V16V15ZM17.9844 2C19.089 2 19.9844 2.89543 19.9844 4H21.9844C21.9844 1.79086 20.1936 0 17.9844 0V2ZM4.98438 2H17.9844V0H4.98438V2ZM2.98438 4C2.98438 2.89543 3.87981 2 4.98438 2V0C2.77524 0 0.984375 1.79086 0.984375 4H2.98438ZM2.98438 12V4H0.984375V12H2.98438ZM4.98437 14C3.8798 14 2.98438 13.1046 2.98438 12H0.984375C0.984375 14.2091 2.77523 16 4.98437 16V14ZM5.98442 14H4.98437V16H5.98442V14ZM6.98442 20V15H4.98442V20H6.98442ZM10.2773 14.2929L5.27731 19.2929L6.69153 20.7071L11.6915 15.7071L10.2773 14.2929ZM17.9844 14L10.9844 14V16L17.9844 16V14ZM19.9844 12C19.9844 13.1046 19.089 14 17.9844 14V16C20.1936 16 21.9844 14.2091 21.9844 12H19.9844ZM19.9844 4V12H21.9844V4H19.9844Z"
			fill="#5F47BF"
		/>
		<path
			d="M25.9844 9V17C25.9844 18.6569 24.6413 20 22.9844 20H21.9844V25L16.9844 20L11.9844 20"
			stroke="#5F47BF"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default Sixteen
