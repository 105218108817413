import React, { SVGProps } from "react"

const PhoneBackdropDesktop = (props: SVGProps<SVGSVGElement>) => (
	<svg
		{...props}
		width="546"
		height="171"
		viewBox="0 0 546 171"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M52.6195 0.108097L498.548 23.3554C528.114 24.8967 549.873 51.6471 545.361 80.9069L541.315 107.15C537.696 130.62 518.069 148.306 494.35 149.471L61.3077 170.727C35.1833 172.009 12.4853 152.94 9.24222 126.986L0.402209 56.2397C-3.45695 25.355 21.5367 -1.51232 52.6195 0.108097Z"
			fill="#F7F7F7"
		/>
	</svg>
)

export default PhoneBackdropDesktop
