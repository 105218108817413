import * as React from "react"
import { SVGProps } from "react"

const Seven = (props: SVGProps<SVGSVGElement>) => (
	<svg
		{...props}
		width="33"
		height="32"
		viewBox="0 0 33 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M3.88867 15.9999C3.88867 13.7448 4.45814 11.6228 5.46111 9.7697C5.74313 9.24865 6.4428 9.17825 6.86174 9.59719L11.0065 13.7419C11.3721 14.1075 11.3721 14.7003 11.0065 15.066L9.11991 16.9525C8.88913 17.1833 8.7918 17.5189 8.8864 17.8312C9.79637 20.8358 12.1759 23.2018 15.1887 24.0928C15.4997 24.1847 15.8326 24.0871 16.062 23.8577L17.9301 21.9896C18.2957 21.624 18.8885 21.624 19.2541 21.9896L23.3988 26.1343C23.8178 26.5532 23.7474 27.2529 23.2263 27.5349C21.3732 28.5379 19.2512 29.1073 16.9961 29.1073C16.2893 29.1073 15.5955 29.0514 14.9189 28.9437C10.4125 28.2261 6.67172 25.2118 4.93418 21.1383C4.26116 19.5604 3.88867 17.8236 3.88867 15.9999Z"
			stroke="#3021E5"
			strokeWidth="1.8725"
			strokeLinejoin="round"
		/>
		<path
			d="M8.7207 5.83482C10.9773 3.99548 13.858 2.89258 16.9963 2.89258C24.2353 2.89258 30.1037 8.76099 30.1037 16.0001C30.1037 19.146 28.9954 22.0331 27.148 24.2922"
			stroke="#3021E5"
			strokeWidth="1.8725"
			strokeLinecap="round"
		/>
		<path
			d="M16.9961 16V8.51001"
			stroke="#3021E5"
			strokeWidth="1.8725"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M16.9963 16L24.4863 16"
			stroke="#3021E5"
			strokeWidth="1.8725"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default Seven
