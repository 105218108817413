import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import One from "./assets/svg/One"
import Two from "./assets/svg/Two"
import Three from "./assets/svg/Three"
import Four from "./assets/svg/Four"
import Five from "./assets/svg/Five"

import Six from "./assets/svg/Six"
import Seven from "./assets/svg/Seven"
import Eight from "./assets/svg/Eight"
import Nine from "./assets/svg/Nine"

import Ten from "./assets/svg/Ten"
import Eleven from "./assets/svg/Eleven"
import Twelve from "./assets/svg/Twelve"
import Thirteen from "./assets/svg/Thirteen"
import Fourteen from "./assets/svg/Fourteen"

import Fifteen from "./assets/svg/Fifteen"
import Sixteen from "./assets/svg/Sixteen"
import Seventeen from "./assets/svg/Seventeen"

import styles from "./styles.module.scss"

export default [
	{
		heading: <>Идеальные условия для&nbsp;старта</>,
		textarea: (
			<>
				Партнёрская программа не&nbsp;требует вложений.
				Приглашаем&nbsp;к&nbsp;сотрудничеству всех, кто связан с&nbsp;общепитом:
			</>
		),
		list: [
			{
				icon: <One />,
				textarea: "Владелец точки общепита",
			},
			{
				icon: <Two />,
				textarea: "Дилер кассового оборудования",
			},
			{
				icon: <Three />,
				textarea: "Бизнес-консультант",
			},
			{
				icon: <Four />,
				textarea: "Ресторанный маркетолог",
			},
			{
				icon: <Five />,
				textarea: "Бухгалтер",
			},
		],
		mediaContent: (
			<StaticImage
				src="./assets/partners-advantages-one.png"
				alt="Заявка на партнерскую программу"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
				className={styles.image1}
			/>
		),
		backdrop: styles.backdrop1,
		theme: "blueSky",
		reverse: false,
	},
	{
		subheading: "Надежность",
		heading: <>Мы всегда рядом</>,
		textarea: (
			<>Предоставляем полную поддержку для партнёра и&nbsp;его представителей</>
		),
		list: [
			{
				icon: <Six />,
				textarea: "Рекламные материалы для рассылок",
			},
			{
				icon: <Seven />,
				textarea: "Техподдержку клиентов 24/7",
			},
			{
				icon: <Eight />,
				textarea: "Регулярное обучение и вебинары",
			},
			{
				icon: <Nine />,
				textarea: "Личного куратора",
			},
		],
		mediaContent: (
			<StaticImage
				src="./assets/partners-advantages-two.png"
				alt="руководитель отдела по работе с партнерами"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
				className={styles.image2}
			/>
		),
		reverse: true,
		rowClass: styles.rowClass2,
	},
	{
		heading: <>Зарабатывай ещё больше</>,
		textarea: (
			<>
				Помимо подключения к&nbsp;системе автоматизации ты&nbsp;можешь
				заработать на&nbsp;дополнительных услугах для своих клиентов.
			</>
		),
		list: [
			{
				icon: <Ten />,
				textarea: "Продажа оборудованиядля старта",
			},
			{
				icon: <Eleven />,
				textarea: "Обучение сотрудников заведения",
			},
			{
				icon: <Twelve />,
				textarea: "Установка и настройка системы",
			},
			{
				icon: <Thirteen />,
				textarea: "Заполнение номенклатуры, техкарт, ведение склада",
			},
			{
				icon: <Fourteen />,
				textarea: "Аудит, бухгалтерия и прочие услуги",
			},
		],
		mediaContent: (
			<StaticImage
				src="./assets/partners-advantages-three.png"
				alt="заработок в партнерской программе"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
				className={styles.image3}
			/>
		),
		backdrop: styles.backdrop3,
		theme: "greenWarm",
		reverse: true,
	},
	{
		heading: <>Подгоняй лиды и&nbsp;считай&nbsp;сприбыль!</>,
		textarea: (
			<>
				Если ты&nbsp;не&nbsp;хочешь продавать сам, то&nbsp;тебе поможет наш
				отдел продаж. Мы&nbsp;знаем, что делает наш продукт уникальным
				и&nbsp;незаменимым. Хорошо понимаем, что нужно предпринимателям
				и&nbsp;рестораторам от&nbsp;системы автоматизации.
			</>
		),
		list: [
			{
				icon: <Fifteen />,
				textarea: "Прозрачная аналитика",
			},
			{
				icon: <Sixteen />,
				textarea: "Быстрая обратная связь",
			},
			{
				icon: <Seventeen />,
				textarea: "Регулярные комиссионные",
			},
		],
		mediaContent: (
			<StaticImage
				src="./assets/partners-advantages-four.png"
				alt="партнерская программа системы автоматизации"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
				className={styles.image4}
			/>
		),
		backdrop: styles.backdrop4,
		theme: "purpleLight",
		reverse: false,
	},
]
