import React from 'react'
import classNames from 'classnames'

import DefaultLayout from '../layout/default'

import ProductPresentation from '../pagesSections/partners/ProductPresentation'
import Advantages from '../pagesSections/partners/Advantages'
import Table from '../pagesSections/partners/Table'
import RequestCall from '../components/_V2/RequestCall'

import advantagesData from '../pagesSections/partners/Advantages/data'
import data from '../pagesSections/partners/RequestCall/data'

import CtaForm from '../pagesSections/index/CtaForm'
import BusinessTypes from '../components/_V2/BusinessTypes'
import businessTypes from '../pages-data/_V2/common/business-types-fourth-compact'
import KnowledgeBase from '../pagesSections/index/KnowledgeBase'

import pageStyles from '../styles/_V2/pagesStyles/styles.module.scss'
import styles from './styles/_V2/partners.module.scss'

export default function PagePartners() {
  const title = 'Партнерская программа Quick Resto — новые возможности бизнеса'
  const linkCanonical = 'https://quickresto.ru/partners/'
  const metaTags = [
    {
      name: 'description',
      content:
				'Партнерская программа Quick Resto — новые возможности для вашего бизнеса. Станьте партнером Quick Resto, привлекайте новых клиентов и зарабатывайте вместе с нами. Получите рекламные материалы для рассылок, личного куратора и техническую поддержку 24/7. Выплачиваем вознаграждение до 100% от счета клиента.',
    },
    {
      property: 'og:url',
      content: linkCanonical,
    },
    {
      property: 'og:title',
      content: title,
    },
    {
      property: 'og:description',
      content:
				'Партнерская программа Quick Resto — новые возможности для вашего бизнеса. Станьте партнером Quick Resto, привлекайте новых клиентов и зарабатывайте вместе с нами. Получите рекламные материалы для рассылок, личного куратора и техническую поддержку 24/7. Выплачиваем вознаграждение до 100% от счета клиента.',
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:image',
      content:
				'https://quickresto.ru/static/aboutPresentation-5751809a2c5964f885ca93ffec15baed.png',
    },
  ]

  return (
    <DefaultLayout
      title={title}
      metaTags={metaTags}
      mainWrapperClassName={pageStyles.pageWrapper}
      linkCanonical={linkCanonical}
    >
      <ProductPresentation className={pageStyles.pageSection} />

      <Advantages
        data={advantagesData[0]}
        className={classNames(pageStyles.pageSection, pageStyles.smallMargin)}
      />

      <Advantages
        data={advantagesData[1]}
        className={classNames(pageStyles.pageSection, pageStyles.smallMargin)}
      />

      <Table
        className={classNames(pageStyles.pageSection, pageStyles.smallMargin)}
      />

      <RequestCall data={data} className={pageStyles.pageSection} />

      <Advantages data={advantagesData[2]} className={pageStyles.pageSection} />

      <Advantages data={advantagesData[3]} className={pageStyles.pageSection} />

      <CtaForm isFry={false} className={pageStyles.pageSection} />

      <BusinessTypes
        data={businessTypes}
        className={classNames(pageStyles.pageSection, styles.partners__nine)}
      />

      <KnowledgeBase type="horizontal" className={classNames(pageStyles.pageSection, styles.partners__ten)} />
    </DefaultLayout>
  )
}
