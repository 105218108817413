import * as React from "react"
import { SVGProps } from "react"

const Five = (props: SVGProps<SVGSVGElement>) => (
	<svg
		{...props}
		width="33"
		height="32"
		viewBox="0 0 33 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M28.752 10.9999C29.3042 10.9999 29.752 11.4476 29.752 11.9999L29.752 22.9999C29.752 23.5522 29.3042 23.9999 28.752 23.9999L20.752 23.9999C20.1997 23.9999 19.752 23.5522 19.752 22.9999L19.752 11.9999C19.752 11.4476 20.1997 10.9999 20.752 10.9999L28.752 10.9999Z"
			stroke="#3021E5"
			strokeWidth="2"
		/>
		<path
			d="M19.752 19.9999H29.7518"
			stroke="#3021E5"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M19.752 15.9999H29.7518"
			stroke="#3021E5"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M24.752 15.9999L24.752 23.9999"
			stroke="#3021E5"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M10.0195 17V8H13.9944C15.0989 8 15.9944 8.89543 15.9944 10V10C15.9944 11.1046 15.0989 12 13.9944 12H10.0195"
			stroke="#3021E5"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M13.9958 15H9.99805"
			stroke="#3021E5"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M23 27.9998H6.99609C5.89152 27.9998 4.99609 27.1402 4.99609 26.0797L4.99609 5.92009C4.99609 4.85966 5.89153 4 6.99609 4L22.9952 4C24.0997 4 24.9952 4.85966 24.9952 5.92009V10.9606"
			stroke="#3021E5"
			strokeWidth="2"
			strokeLinecap="round"
		/>
	</svg>
)

export default Five
