import * as React from "react"
import { SVGProps } from "react"

const Fifteen = (props: SVGProps<SVGSVGElement>) => (
	<svg
		{...props}
		width="33"
		height="32"
		viewBox="0 0 33 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M28.7597 28L24.0919 23.332"
			stroke="#5F47BF"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M28.3092 10.3431L22.6523 16L19.8239 13.1716L14.1671 18.8284L11.3386 16L5.68178 21.6568"
			stroke="#5F47BF"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M24.0815 8.9433C22.2707 7.12521 19.7648 6 16.9961 6C11.4732 6 6.99609 10.4772 6.99609 16C6.99609 21.5228 11.4732 26 16.9961 26C22.5189 26 26.9961 21.5228 26.9961 16C26.9961 14.725 26.7575 13.5057 26.3225 12.3844"
			stroke="#5F47BF"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default Fifteen
