import * as React from "react"
import { SVGProps } from "react"

const Seventeen = (props: SVGProps<SVGSVGElement>) => (
	<svg
		{...props}
		width="33"
		height="32"
		viewBox="0 0 33 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M15.8198 4.88339C16.5205 4.3722 17.4713 4.3722 18.1721 4.88339L19.6139 5.93516C19.9565 6.18507 20.3698 6.31936 20.7939 6.31855L22.5786 6.31515C23.446 6.3135 24.2151 6.87233 24.4816 7.6978L25.0299 9.3962C25.1602 9.79975 25.4156 10.1513 25.7591 10.3999L27.205 11.4462C27.9077 11.9547 28.2015 12.8589 27.9319 13.6834L27.3771 15.3797C27.2453 15.7827 27.2453 16.2173 27.3771 16.6203L27.9319 18.3166C28.2015 19.1411 27.9077 20.0453 27.205 20.5538L25.7591 21.6001C25.4156 21.8487 25.1602 22.2002 25.0299 22.6038L24.4816 24.3022C24.2151 25.1277 23.446 25.6865 22.5786 25.6848L20.7939 25.6814C20.3698 25.6806 19.9565 25.8149 19.6139 26.0648L18.1721 27.1166C17.4713 27.6278 16.5205 27.6278 15.8198 27.1166L14.3779 26.0648C14.0353 25.8149 13.622 25.6806 13.198 25.6814L11.4133 25.6848C10.5458 25.6865 9.77668 25.1277 9.5102 24.3022L8.96193 22.6038C8.83166 22.2002 8.57623 21.8487 8.23268 21.6001L6.78683 20.5538C6.08411 20.0453 5.79032 19.1411 6.05993 18.3166L6.61467 16.6203C6.74648 16.2173 6.74648 15.7827 6.61467 15.3797L6.05993 13.6834C5.79032 12.8589 6.08411 11.9547 6.78683 11.4462L8.23268 10.3999C8.57623 10.1513 8.83166 9.79975 8.96193 9.3962L9.5102 7.6978C9.77668 6.87233 10.5458 6.3135 11.4133 6.31515L13.198 6.31855C13.622 6.31936 14.0353 6.18507 14.3779 5.93516L15.8198 4.88339Z"
			stroke="#5F47BF"
			strokeWidth="2"
		/>
		<path
			d="M13.8281 20.1669L20.1619 11.8331"
			stroke="#5F47BF"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<circle
			cx="13.4961"
			cy="13.3337"
			r="1.5"
			stroke="#5F47BF"
			strokeWidth="2"
		/>
		<circle
			cx="20.4961"
			cy="18.6664"
			r="1.5"
			transform="rotate(-180 20.4961 18.6664)"
			stroke="#5F47BF"
			strokeWidth="2"
		/>
	</svg>
)

export default Seventeen
