import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import styles from "./styles.module.scss"

export default {
	pageName: "Партнёрская программа",
	desktopTitle: <>Помогай другим и&nbsp;зарабатывай</>,
	mobileTitle: <>Помогай другим и&nbsp;зарабатывай</>,
	subTitle: (
		<p>
			Каждый месяц в&nbsp;России открывается более 2&nbsp;000 новых точек
			общепита. Стань партнёром Quick Resto, привлекай новых клиентов
			и&nbsp;зарабатывай вместе с&nbsp;нами.
		</p>
	),
	desktopCtaDetails: (
		<>
			Честная рефералка с&nbsp;быстрыми выплатами,
			без&nbsp;офшоров&nbsp;и&nbsp;смс
		</>
	),
	tabletCtaDetails: (
		<>
			Честная рефералка с&nbsp;быстрыми выплатами,
			без&nbsp;офшоров&nbsp;и&nbsp;смс
		</>
	),
	mobileCtaDetails: (
		<>
			Честная рефералка с&nbsp;быстрыми выплатами,
			без&nbsp;офшоров&nbsp;и&nbsp;смс
		</>
	),
	cta: "Стать партнёром",
	ctaLink: {
		text: "Стать партнёром",
		href: "#popup",
		useGatsbyLink: false,
	},
	figure: (
		<StaticImage
			className={styles.image}
			src="./assets/partners-product-presentation.png"
			alt="Партнерская программа Quick Resto"
			objectFit="contain"
			placeholder="blurred"
			quality={90}
		/>
	),
}
