import * as React from "react"
import { SVGProps } from "react"

const Fourteen = (props: SVGProps<SVGSVGElement>) => (
	<svg
		{...props}
		width="32"
		height="32"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M27.7559 10.9999C28.3081 10.9999 28.7559 11.4476 28.7559 11.9999L28.7559 22.9999C28.7559 23.5522 28.3081 23.9999 27.7559 23.9999L19.7559 23.9999C19.2036 23.9999 18.7559 23.5522 18.7559 22.9999L18.7559 11.9999C18.7559 11.4476 19.2036 10.9999 19.7559 10.9999L27.7559 10.9999Z"
			stroke="#1BC595"
			strokeWidth="2"
		/>
		<path
			d="M18.7559 19.9999H28.7557"
			stroke="#1BC595"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M18.7559 15.9999H28.7557"
			stroke="#1BC595"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M23.7559 15.9999L23.7559 23.9999"
			stroke="#1BC595"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M9.02344 17V8H12.9983C14.1028 8 14.9983 8.89543 14.9983 10V10C14.9983 11.1046 14.1028 12 12.9983 12H9.02344"
			stroke="#1BC595"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M12.9997 15H9.00195"
			stroke="#1BC595"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M22.0039 27.9998H6C4.89543 27.9998 4 27.1402 4 26.0797L4 5.92009C4 4.85966 4.89543 4 6 4L21.9991 4C23.1036 4 23.9991 4.85966 23.9991 5.92009V10.9606"
			stroke="#1BC595"
			strokeWidth="2"
			strokeLinecap="round"
		/>
	</svg>
)

export default Fourteen
