import * as React from "react"
import { SVGProps } from "react"

const Eleven = (props: SVGProps<SVGSVGElement>) => (
	<svg
		{...props}
		width="30"
		height="28"
		viewBox="0 0 30 28"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect
			x="1"
			y="1.23189"
			width="28"
			height="19.4796"
			rx="2"
			stroke="#1BC595"
			strokeWidth="2"
		/>
		<path
			d="M7.69531 26.7683H22.304"
			stroke="#1BC595"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M12.5645 20.8842L12.5645 26.7684"
			stroke="#1BC595"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M17.4336 20.8842L17.4336 26.7684"
			stroke="#1BC595"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M10.6797 10.4077V14.4884C10.6797 15.5717 12.6051 16.4499 14.9801 16.4499C17.3552 16.4499 19.2806 15.5717 19.2806 14.4884L19.2805 14.4779L19.2806 10.4077M7.67578 8.89757L14.9801 5.49343L22.2845 8.89757L14.9801 12.3017L7.67578 8.89757Z"
			stroke="#1BC595"
			strokeWidth="2"
			strokeLinejoin="round"
		/>
	</svg>
)

export default Eleven
