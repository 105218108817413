import React from "react"
import classNames from "classnames"

import data from "./data"

import styles from "./styles.module.scss"

interface Props {
	className?: string // Класс
}

export default function Table(props: Props) {
	const renderTable = (): JSX.Element => (
		<div className={styles.table}>
			{data.table.map(row => (
				<div key={row.heading} className={styles.table__row}>
					<div className={styles.table__col}>
						<h3>{row.heading}</h3>
					</div>
					<div className={styles.table__col}>
						<ul>
							{row.list.map(item => (
								<li key={item}>{item}</li>
							))}
						</ul>
					</div>
				</div>
			))}
		</div>
	)

	return (
		<section className={classNames(styles.section, props.className)}>
			<h2 className={styles.heading}>{data.heading}</h2>

			{data.table.length > 0 && renderTable()}
		</section>
	)
}
