import React from "react"
import classNames from "classnames"

import styles from "./styles.module.scss"

interface Props {
	className?: string // Класс
	data: {
		subheading?: string
		heading: string
		textarea: string
		list: [
			{
				icon: JSX.Element
				textarea: string
			}
		]
		mediaContent: JSX.Element
		backdrop?: string
		theme?: string
		reverse: boolean
		rowClass?: string
	}
}

export default function Advantages(props: Props) {
	const data = props.data

	return (
		<section
			className={classNames(
				styles.section,
				{
					[styles.section__blueSky]: data.theme === "blueSky",
					[styles.section__greenWarm]: data.theme === "greenWarm",
					[styles.section__purpleLight]: data.theme === "purpleLight",
				},
				props.className
			)}
		>
			<div
				className={classNames(
					styles.content__row,
					{
						[styles.content__rowReverse]: data.reverse,
					},
					data.rowClass
				)}
			>
				<div className={styles.content__column}>{data.mediaContent}</div>
				<div className={styles.content__column}>
					{data.subheading && (
						<span className={styles.subheading}>{data.subheading}</span>
					)}
					<h2 className={styles.heading}>{data.heading}</h2>
					<div className={styles.textarea}>{data.textarea}</div>

					<ul className={styles.list}>
						{data.list.map(item => (
							<li key={item.textarea} className={styles.list__item}>
								{item.icon}
								<span className={styles.list__itemTextarea}>
									{item.textarea}
								</span>
							</li>
						))}
					</ul>
				</div>
			</div>

			<div className={data.backdrop}></div>
		</section>
	)
}
