import React from "react"

import TakeMyMoney from "./assets/TakeMyMoney"

export default {
	icon: <TakeMyMoney />,
	heading: <>Стартовый пакет&nbsp;партнёра</>,
	textarea: (
		<>
			Всё что нужно для начала работы в&nbsp;партнёрской программе Quick&nbsp;Resto.
		</>
	),
	btn: "Погнали!",
	inputTitle: "Телефон",
	successTitle: "Отлично!",
	successDetails: "Спасибо что оставили номер телефона",
	successAction: "Хорошо",
}
