import React, { SVGProps } from "react"

const PhoneBackdropTablet = (props: SVGProps<SVGSVGElement>) => (
	<svg
		{...props}
		width="313"
		height="132"
		viewBox="0 0 313 132"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M38.3398 0.653465L280.894 21.877C300.47 23.5898 314.799 41.0787 312.629 60.6089L309.241 91.1087C307.331 108.299 293.15 121.516 275.869 122.215L42.3469 131.657C23.7337 132.41 7.7925 118.451 6.08089 99.9016L0.43702 38.7361C-1.56863 16.9998 16.5943 -1.24927 38.3398 0.653465Z"
			fill="#F7F7F7"
		/>
	</svg>
)

export default PhoneBackdropTablet
